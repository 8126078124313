<template>
  <div  class="wrap">
    <!-- 头部组件 -->
    <gw-header ref="gwheader" page="yyhAppInfo"></gw-header>
    <!-- banner图 -->
    <div class="banner">
      <img :src="banner" class="banner-img">
    </div>

    <!-- 底部组件 -->
    <gw-footer></gw-footer>
  </div>
</template>
<script>
import gwHeader from '../../components/header.vue'
import gwFooter from '../../components/footer.vue'
export default {
  name: "yyhAppInfo",
  components: {gwHeader, gwFooter},
  data(){
    return{
      msg: "ceshi",
      banner: 'https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/yyh_banner_new.png'
    }
  },
  created() {

  }
}
</script>
<style lang="scss" scoped>

.wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.banner {
  height: 80rem;
  width: 100%;

  .banner-img {
    object-fit: cover;
    width: 100%;
    display: block;
    height: 100%;
    margin: 0 auto;
  }
}
</style>