import home from "./views/home/index.vue";  //首页
import solutionIndex from "./views/solution/index.vue";  //解决方案
import caseIndex from "./views/case/index.vue";  //客户案例
import newsList from "./views/news/list.vue";  //新闻资讯
import newsDetail from "./views/news/detail.vue";  //新闻详情
import recruitList from "./views/recruit/list.vue";  //招聘列表
import recruitDetail from "./views/recruit/detail.vue";  //招聘详情
import contactIndex from "./views/contact/index.vue";  //联系我们
import aboutIndex from "./views/about/index.vue";  //关于我们
import refresh from "./views/solution/refresh.vue";  //刷新
import yyhAppInfo from "./views/solution/yyhAppInfo.vue";
export default[
    {
        path:"/",
        name: "home",
        component: home,
        meta: {
            title: "冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/solutionIndex",
        name: "solutionIndex",
        component: solutionIndex,
        meta: {
            title: "解决方案-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/caseIndex",
        name: "caseIndex",
        component: caseIndex,
        meta: {
            title: "客户案例-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/newsList",
        name: "newsList",
        component: newsList,
        meta: {
            title: "新闻资讯-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/newsDetail",
        name: "newsDetail",
        component: newsDetail,
        meta: {
            title: "新闻详情-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/recruitList",
        name: "recruitList",
        component: recruitList,
        meta: {
            title: "人才招聘-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/recruitDetail",
        name: "recruitDetail",
        component: recruitDetail,
        meta: {
            title: "招聘详情-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/contactIndex",
        name: "contactIndex",
        component: contactIndex,
        meta: {
            title: "联系我们-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/aboutIndex",
        name: "aboutIndex",
        component: aboutIndex,
        meta: {
            title: "关于我们-冠通新创科技（上海）有限公司"
        }
    },
    {
        path:"/refresh",
        name: "refresh",
        component: refresh,
        meta: {
            title: "刷新-冠通新创科技（上海）有限公司"
        }
    },
    {
      path:"/yyhAppInfo",
      name: "yyhAppInfo",
      component: yyhAppInfo,
      meta: {
          title: "解决方案-冠通新创科技（上海）有限公司"
      }
  },
]
