<template>
  <div class="warp">
<!--    header-->
    <gw-header page="case" ref="gwheader"></gw-header>
<!--    banner-->
    <div class="case-banner">
      <img :src="util.getImageUrl('case_banner.png')" />
    </div>
<!--    客户案例-->
    <div class="case">
      <div class="container">
        <div class="title">
          <div>
            <h1>客户案例</h1>
          </div>
        </div>
        <el-row class="flex-row">
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" class="case-item" v-for="(item,index) in caseList" :key="index">
            <div class="case-item-wrap" @click="caseClick(index)">
              <div class="case-item-img">
                <img :src="util.getImageUrl(item.imgUrl)" />
              </div>
              <div class="case-item-content">
                <img :src="util.getImageUrl(item.logoImgUrl)" />
                <h3>{{item.title}}</h3>
                <p>{{item.subTitle}}</p>
                <div class="case-item-tag">
                  <span v-for="(tagItem, tagIndex) in item.tagList" :key="tagIndex">{{tagItem.title}}</span>
                </div>
              </div>
            </div>

          </el-col>
        </el-row>

      </div>
    </div>
<!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from '../../components/header.vue';
import gwFooter from '../../components/footer.vue';
export default {
  name: 'Case',
  components: { gwHeader, gwFooter },
  data() {
    return {
      caseList: [  //案例列表
        {
          title: "智能云监管平台 ",
          subTitle: "300+上海食品生产企业接入平台 ",
          imgUrl: "case1.png",
          logoImgUrl: "case_logo1.png",
          tagList: [
            { title: "监管"},
            { title: "便利"},
            { title: "300+食品企业接入"},
          ],
        },
        {
          title: "益友荟 ",
          subTitle: "高端健康数字服务平台",
          imgUrl: "case_yyh.png",
          logoImgUrl: "case_logo_yyh.png",
          tagList: [
            { title: "数字化"},
            { title: "便利"},
            { title: "合伙人10000+"},
          ],
        },
        {
          title: "黑金芝麻 ",
          subTitle: "赋能50000家母婴实体门店",
          imgUrl: "case2.png",
          logoImgUrl: "case_logo2.png",
          tagList: [
            { title: "医疗"},
            { title: "便利"},
            { title: "赋能5000+门店"},
          ],
        },
        {
          title: "销帮主医疗器械经营管理软件",
          subTitle: "合作企业超  3000+ ",
          imgUrl: "case3.png",
          logoImgUrl: "case_logo3.png",
          tagList: [
            { title: "医疗"},
            { title: "软件"},
            { title: "合作企业3000+"},
          ],
        },
        {
          title: "食品追溯管理和公众查询平台",
          subTitle: "入驻企业超  7000+",
          imgUrl: "case4.png",
          logoImgUrl: "case_logo4.png",
          tagList: [
            { title: "监管"},
            { title: "便利"},
            { title: "入驻企业7000+"},
          ],
        },
        {
          title: "菌宝家族项目运营 ",
          subTitle: "3星特许经营店主团队100+",
          imgUrl: "case5.png",
          logoImgUrl: "case_logo5.png",
          tagList: [
            { title: "数字化"},
            { title: "便利"},
            { title: "用户人数300000"},
          ],
        },
        {
          title: "萌百格新零售",
          subTitle: "入驻合作门店超1000家 ",
          imgUrl: "case6.png",
          logoImgUrl: "case_logo6.png",
          tagList: [
            { title: "零售"},
            { title: "便利"},
            { title: "会员人数超30万"},
          ],
        },
        {
          title: "万礼汇礼品营销服务平台 ",
          subTitle: "入驻合作门店超500家 ",
          imgUrl: "case7.png",
          logoImgUrl: "case_logo7.png",
          tagList: [
            { title: "零售"},
            { title: "便利"},
            { title: "入驻门店500+"},
          ],
        },
        {
          title: "MOS特许经营管理系统 ",
          subTitle: "全面上线",
          imgUrl: "case8.png",
          logoImgUrl: "case_logo8.png",
          tagList: [
            { title: "零售"},
            { title: "便利"},
            { title: "连锁门店20000+"},
          ],
        },
        {
          title: "晨冠生物-数字营销 ",
          subTitle: "会员数：超30万 ",
          imgUrl: "case9.png",
          logoImgUrl: "case_logo9.png",
          tagList: [
            { title: "零售"},
            { title: "便利"},
            { title: "会员数超300000"},
          ],
        },
      ],
    }
  },
  methods: {
    jumpSolution: function (){
      this.$router.push({path: "/solutionIndex"})
    },
    caseClick(index){
      if(index == 1){
        this.util.jumpPage('yyhAppInfo', {});
      }
    },

  },
  mounted(){
    //  初始化动画效果库
    new this.$wow.WOW({
      live: true
    }).init();
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .case-banner{
    width: 100%;
    height: 80rem;
    overflow: hidden;
    position: relative;
    img{
      object-fit: cover;
      width: 100%;
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }
  .title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      h1{
        font-size: 2.8rem;
        line-height: 2.8rem;
        font-weight: bold;
        color: $black-color;
      }
      img{
        width: 6.1rem;
        height: 4.8rem;
        display: block;
        transform: translateX(-2rem);
      }
    }
    p{
      font-size: 1.4rem;
      font-weight: 400;
      color: $footer-color;
      text-align: center;
    }
  }
//  客户案例
  .case{
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 5rem;
    background-color: $grey-color;
    .case-item{
      height: auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 1.2rem;
      margin: 1.2rem 0;
      .case-item-wrap{
        width: 100%;
        height: auto;
        overflow: hidden;
        background-color: $white-color;
        .case-item-img{
          width: 100%;
          height: auto;
          overflow: hidden;
          cursor: pointer;
          img{
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s;
          }
        }
        .case-item-img:hover>img{
          transform: scale(1.2);
        }
        .case-item-content{
          width: 90%;
          margin: 0 auto;
          img{
            display: block;
            margin: 3rem 0;
          }
          h3{
            font-size: 1.8rem;
            font-weight: bold;
            color: $black-color;
          }
          p{
            font-size: 1.4rem;
            font-weight: 400;
            color: $text-color;
          }
          .case-item-tag{
            width: 100%;
            border-top: 0.1rem solid #E8E8E8;
            margin: 5rem 0 3rem;
            padding: 2rem 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            span{
              padding: 0.3rem 1rem;
              border-radius: 1.4rem;
              margin-right: 1rem;
              font-size: 1.4rem;
              margin-bottom: 1rem;
            }
            span:first-of-type{
              background-color: $tag-one-color;
              color: #C5A6A6;
            }
            span:nth-of-type(2){
              background-color: $tag-two-color;
              color: #C1B39F;
            }
            span:nth-of-type(3){
              background-color: $tag-three-color;
              color: #9BA7B3;
            }

          }
        }

      }
    }
  }
</style>
